<template>
<!-- 赛事-图片列表 -->
    <div class="event">
        <div class="photo">
            <img :src="image" v-for="(image, i) in images" :key="i" @click="skipslideshow(i)">
        </div>
    </div>
</template>

<script>
import bus from "../../../assets/js/bus";

export default {
    data () {
        return {
            images:[]
            
        }
    },
    methods: {
        skipslideshow(num){
            let that = this;
            this.$store.state.slideshow = true;
            // 当照片数量小于5张时，直接取照片数组
            if(that.images.length <= 5){
                setTimeout(() => {
                 bus.$emit('match_photo', that.images);
                }, 200);
            }else{
                let list = [];
                let max = num+4;
                that.images.forEach((item,index)=>{
                    // 否则当当前点击的照片为非最后5张时，取当前照片开始的5张照片
                    if(index <= max && index >= num && that.images.length-5 > num){
                        list.push(item)
                    }else if(that.images.length > 5 && that.images.length-5 <= num){
                        // 否则取最后5张照片
                        list = that.images.slice(that.images.length-5,that.images.length)
                    }
                })
                setTimeout(() => {
                    bus.$emit('match_photo', list);
                }, 200);
            }
        },
        getImage(){
            let that = this;
            bus.$on("match_images",res=>{
                // console.log(res)
                that.images = res;
            })
        }
    },
    created () {
        let that = this;
        that.getImage()
        
    },
    mounted () {
        let that = this;
    },
    destroyed () {
        bus.$off("match_images")
    }
}
</script>

<style scoped>
.event{
    width:18.26rem;
    height:1.5rem;
    position: relative;
    margin: auto;
    overflow: hidden;
}
.event>div>img{
    width:2.01rem;
    height:1.25rem;
    margin-bottom: 0.05rem;
}
.photo{
    width: 100%;
    height:1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-wrap:wrap;
    overflow-y: scroll;
}
 /*滚动条样式*/
.photo::-webkit-scrollbar {
    width: 0.05rem;    
    height: 0rem;
}


.photo::-webkit-scrollbar-thumb {
    border-radius: 0.1rem;
    box-shadow: inset 0 0 0.05rem #bec5ce;
    background: #7EA7E2;
}
.photo::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.05rem #7EA7E2;
    border-radius: 0.1rem;
}
</style>